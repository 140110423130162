var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "_content-view" },
    [
      _c(
        "div",
        { staticClass: "mb-4" },
        [
          _c(
            "vs-button",
            {
              attrs: { type: "border", icon: "undo" },
              on: { click: _vm.goBack },
            },
            [_vm._v(_vm._s(_vm.$t("common.back")))]
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          key: _vm.key,
          staticClass: "w-full mb-1 mt-2 flex justify-end gap-4",
        },
        [
          _vm.classTitle
            ? _c("label", [
                _c("label", { staticClass: "font-bold" }, [_vm._v(" Turma: ")]),
                _vm._v(" " + _vm._s(_vm.classTitle) + " "),
              ])
            : _vm._e(),
          _c("label", [
            _vm._.get(_vm.model, "is_released", false)
              ? _c("label", { staticClass: "font-bold" }, [
                  _vm._v(" " + _vm._s(_vm.$t("liberado-em")) + " "),
                ])
              : _c("label", { staticClass: "font-bold" }, [
                  _vm._v(" " + _vm._s(_vm.$t("sera-liberado-em")) + " "),
                ]),
            _c("label", { key: _vm.key }, [
              _vm._v(" " + _vm._s(_vm.releaseAt) + " "),
            ]),
          ]),
        ]
      ),
      _c("vx-card", { key: _vm.key, attrs: { title: _vm.title } }, [
        _vm._.get(_vm.model, "is_released", false)
          ? _c(
              "div",
              [
                _vm.model
                  ? _c("div", { staticClass: "__content-text" }, [
                      _vm.instructions
                        ? _c("div", { staticClass: "__text" }, [
                            _c("div", {
                              directives: [
                                {
                                  name: "html-safe",
                                  rawName: "v-html-safe",
                                  value: _vm.instructions,
                                  expression: "instructions",
                                },
                              ],
                            }),
                          ])
                        : _vm._e(),
                    ])
                  : _vm._e(),
                _c("vs-divider", { staticClass: "w-full mt-1" }),
                _vm.id
                  ? _c(
                      "div",
                      { staticClass: "mt-2 w-full" },
                      [
                        _c("content-media-list", {
                          attrs: { "show-delete": false, medias: _vm.medias },
                          on: { deleteMedia: _vm.deleteMedia },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            )
          : _c(
              "div",
              [
                _c(
                  "vs-alert",
                  {
                    staticClass: "mt-5 text-warning mb-4",
                    attrs: {
                      title: _vm.$t("alerta"),
                      color: "rgb(231, 154, 23)",
                      active: "true",
                    },
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("este-conteudo-ainda-nao-foi-liberado")) +
                        " "
                    ),
                  ]
                ),
              ],
              1
            ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }