<template>
  <div class="_content-view">
    <div class="mb-4">
      <vs-button
        type="border"
        @click="goBack"
        icon="undo"
      >{{ $t('common.back') }}</vs-button>
    </div>
    <div class="w-full mb-1 mt-2 flex justify-end gap-4" :key="key">
      <label v-if="classTitle">
        <label class="font-bold">
          Turma:
        </label>
        {{ classTitle }}
      </label>
      <label>
        <label v-if="_.get(model, 'is_released', false)" class="font-bold">
          {{ $t('liberado-em') }}
        </label>
        <label class="font-bold" v-else>
          {{ $t('sera-liberado-em') }}
        </label>
        <label :key="key">
        {{ releaseAt }}
        </label>
      </label>
    </div>
    <vx-card :title="title" :key="key">
      <div v-if="_.get(model, 'is_released', false)">
        <div class="__content-text" v-if="model">
          <div class="__text" v-if="instructions">
            <div v-html-safe="instructions"/>
          </div>
        </div>
        
        <vs-divider class="w-full mt-1"/>
        <div v-if="id" class="mt-2 w-full">
          <content-media-list :show-delete="false" @deleteMedia="deleteMedia" :medias="medias"/>
        </div>
      </div>
      <div v-else>
        <vs-alert
        :title="$t('alerta')"
        color="rgb(231, 154, 23)"
        active="true"
        class="mt-5 text-warning mb-4">
          {{ $t('este-conteudo-ainda-nao-foi-liberado') }}
        </vs-alert>
      </div>
    </vx-card>
    
  </div>
</template>

<script>
import ContentService from '@/services/api/ContentService'
import ContentMediaList from '../ContentMediaList.vue'
export default {
  components: { ContentMediaList },
  props: {
    id: {
      default: null,
      type: Number
    }
  },
  data: () => ({
    key: 0,
    service: null,
    model: {
      from_class: {
        id: null,
        title: null,
      }
    },
    medias: []
  }),
  computed: {
    classTitle() {
      return this.model && this.model.from_class && this.model.from_class.title
    },
    classId() {
      return this.model && this.model.from_class && this.model.from_class.id
    },
    instructions() {
      return this._.get(this.model, 'instructions')
    },
    title() {
      return this._.get(this.model, 'title')
    },
    releaseAt() {
      return this.model && this.model.release_at && this.$utils.format.longDate(this.model.release_at)
    }
  },
  mounted() {
    this.service = ContentService.build(this.$vs)
    if (this.id) {
      this.getModel(this.id)
      this.loadMedias(this.id)
    }
  },
  methods: {
    loadMedias(contentId) {
      this.service.getMedias(contentId).then(
        response => {
          if (this._.isArray(response) && response.length > 0) {
            this.medias = response
          } else {
            this.medias = []
          }
          console.warn('No media found for this Content.')
        },
        error => {
        }
      )
    },
    getModel(id) {
      this.$vs.loading()
      this.service.read(id, {data_access: this._.get(this, '$route.query.a')}).then(
        response => {
          this.$vs.loading.close()
          this.fillModel(response)
          this.$nextTick(() => {
            this.key++
          })
        },
        error => {
          this.$vs.loading.close()
        }
      )
    },
    fillModel(model) {
      this.model = model
    },
    goBack() {
      if (this.classId) {
        this.$router.push(`/classes_students/${this.classId}/overview?t=contents`)
      } else {
        this.$router.push('/classes_students')
      }
    }
  }
}
</script>

<style lang="scss">
._content-view .__content-text {
  @apply flex;
  @apply flex-col;
  @apply gap-2;
  & .__text {
    @apply w-full;
  }
}
</style>